/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("./montserrat-regular.eot");
  src: url("./montserrat-regular.eot?#iefix") format("embedded-opentype"), url("./montserrat-regular.woff2") format("woff2"), url("./montserrat-regular.woff") format("woff"), url("./montserrat-regular.ttf") format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url("./montserrat-bold.eot");
  src: url("./montserrat-bold.eot?#iefix") format("embedded-opentype"), url("./montserrat-bold.woff2") format("woff2"), url("./montserrat-bold.woff") format("woff"), url("./montserrat-bold.ttf") format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url("./Montserrat-ExtraBold.ttf") format("truetype");
}
