.wrap.main {
  position: relative;
  min-width: 1260px;
  overflow: hidden;
}

.header .gnb a {
  color: #fff !important;
}
.header .logo {
  background-image: url("assets/img/common/logo_header_main.png");
}
.header .language .btn-language {
  background-image: url("assets/img/common/ico_language_main.png");
}
.header .btn-myaccount {
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  line-height: 32px;
  border-radius: 4px;
  border-color: rgba(255, 255, 255, 0.5);
}

.top {
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 2;
}
.top .inner {
  position: relative;
  min-width: 1264px;
  height: 1080px;
  padding: 222px 0 0 0;
  box-sizing: border-box;
  margin: 0 auto;
  background: var(--color-green) url(assets/img/main/bg_main_visual.png) center
    top no-repeat;
  background-size: 2580px auto;
}
.top .inner h1 {
  margin-bottom: 22px;
  margin: 0 0 22px;
  text-align: center;
  font-size: 70px;
  font-weight: 800;
  line-height: 1.26;
  letter-spacing: -0.7px;
  color: #fff;
}
.top .inner p {
  font-size: 22px;
  line-height: 1.64;
  text-align: center;
  letter-spacing: -0.22px;
  color: #fff;
}
.top .inner .btns {
  width: 460px;
  padding: 48px 0 0 0;
  margin: 0 auto;
  text-align: center;
}
.top .inner .btns a {
  float: left;
  margin: 0 8px;
  width: 214px;
  height: 72px;
  text-align: left;
  line-height: 72px;
  padding-left: 30px;
  color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  background: var(--color-font-secondary) url("assets/img/main/ico_top.png")
    160px center no-repeat;
  background-size: 24px auto;
}
.top .inner:after {
  position: absolute;
  left: 50%;
  bottom: -140px;
  margin-left: -524px;
  display: block;
  content: "";
  width: 1048px;
  height: 600px;
  border-radius: 24px;
  box-shadow: 0 30px 40px 0 rgba(51, 61, 74, 0.12);
  background: url("assets/img/main/img_visual.png") no-repeat;
  background-size: 1048px auto;
}

.roi {
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
}
.roi .inner {
  position: relative;
  min-width: 1264px;
  padding: 406px 0 90px 0;
  box-sizing: border-box;
  background: url("assets/img/main/bg_roi.png") center 57px no-repeat;
  background-size: 1657px auto;
}
.roi h2 {
  margin-bottom: 72px;
  text-align: center;
  font-size: 32px;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: -0.32px;
  text-align: center;
  color: var(--color-font-secondary);
}
.roi h2 em {
  font-style: normal;
  color: var(--color-font-green);
}
.roi .list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 1264px;
  margin: 0 auto;
}
.roi .item {
  width: 408px;
  height: 182px;
  padding: 36px 24px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--color-border-box);
}
.roi .item dt {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.75;
  color: var(--color-font-secondary);
}
.roi .item dd {
  font-size: 15px;
  line-height: 1.6;
  color: var(--color-font-gray);
}
.roi .bg-dot {
  padding: 48px 0 32px 0;
  text-align: center;
}
.roi .bg-dot span {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 15px;
  border-radius: 6px;
  background: var(--color-green);
}
.roi .txt {
  font-size: 16px;
  text-align: center;
  line-height: 1.63;
  font-weight: bold;
  color: var(--color-font-secondary);
}

.solutions {
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  background: var(--color-bg-main);
}
.solutions .inner {
  position: relative;
  width: 1264px;
  height: 930px;
  padding: 328px 0 208px 0;
  margin: 0 auto;
  box-sizing: border-box;
}
.solutions .inner:after {
  position: absolute;
  left: -208px;
  top: 90px;
  content: "";
  display: block;
  width: 148px;
  height: 148px;
  background: url("assets/img/main/bg_dot.png") no-repeat;
  background-size: 148px auto;
}
.solutions .inner:before {
  position: absolute;
  right: -76px;
  top: 244px;
  content: "";
  display: block;
  width: 629px;
  height: 478px;
  background: url("assets/img/main/bg_solutions.png") center no-repeat;
  background-size: 629px auto;
}
.solutions h2 {
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: -0.48px;
  color: var(--color-font-secondary);
}
.solutions p {
  width: 540px;
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-secondary);
}
.solutions .btns {
  overflow: hidden;
  padding-top: 60px;
}
.solutions .btns a {
  float: left;
  margin-right: 16px;
  display: block;
  width: 246px;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  background-color: var(--color-green);
}

.technology {
  clear: both;
  position: relative;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  overflow: hidden;
  background: var(--color-bg-main);
}
.technology .inner {
  position: relative;
  width: 1264px;
  height: 713px;
  padding: 0 0 254px 724px;
  margin: 0 auto;
  box-sizing: border-box;
}
.technology .inner:before {
  position: absolute;
  left: -76px;
  top: 0;
  content: "";
  display: block;
  width: 770px;
  height: 460px;
  background: url("assets/img/main/bg_technology.png") center no-repeat;
  background-size: 629px auto;
}
.technology h2 {
  padding: 75px 0 30px 0;
  font-size: 48px;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: -0.48px;
  color: var(--color-font-secondary);
}
.technology p {
  width: 540px;
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-secondary);
}
.technology .btns {
  overflow: hidden;
  padding-top: 60px;
}
.technology .btns a {
  float: left;
  margin-right: 16px;
  display: block;
  width: 246px;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  background-color: var(--color-green);
}

.ai-with-patents {
  text-align: center;
}
.ai-with-patents .inner {
  width: 1264px;
  margin: 0 auto;
  padding: 150px 0;
}
.ai-with-patents h2 {
  margin-bottom: 24px;
  font-size: 48px;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: -0.48px;
  color: var(--color-font-secondary);
}
.ai-with-patents p {
  width: 832px;
  margin: 0 auto 52px auto;
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-primary);
}
.ai-with-patents .list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.ai-with-patents .item {
  position: relative;
  height: 396px;
  padding: 36px 20px 104px 28px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--color-bg-content1);
}
.ai-with-patents .item dt {
  font-size: 16px;
  font-weight: 800;
  line-height: 1.75;
  margin-bottom: 20px;
  color: var(--color-font-secondary);
}
.ai-with-patents .item dd {
  font-size: 15px;
  line-height: 1.6;
  text-align: center;
  color: var(--color-font-gray);
}
.ai-with-patents .item .btn-more {
  position: absolute;
  left: 116px;
  bottom: 32px;
  display: block;
  width: 184px;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  background-color: var(--color-bg-btn-dark);
}

.contact-us {
  position: relative !important;
  width: 1264px !important;
  height: 280px !important;
  padding: 82px 0 0 108px !important;
  box-sizing: border-box !important;
  margin: 0 auto !important;
  background: url("assets/img/main/bg_contact.png") no-repeat !important;
  background-size: 1264px auto !important;
}
.contact-us h2 {
  margin-bottom: 24px;
  font-size: 36px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -0.36px;
  color: #fff;
}
.contact-us p {
  opacity: 0.6;
  font-size: 16px;
  line-height: 1.63;
  color: #fff;
}
.contact-us .btn-more {
  position: absolute;
  right: 108px;
  top: 148px;
  width: 184px;
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  text-align: center;
  line-height: 50px;
  padding: 0 24px;
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.7);
}

/*----------------------------------------
            mobile
-------------------------------------------*/
@media all and (max-width: 1023px) {
  .wrap.main {
    min-width: 100%;
  }

  .header .logo {
    background-image: url("assets/img/common/logo_header_main_m.png");
  }
  .header .btn-menu-open {
    background-image: url("assets/img/common/btn_menu_open_main.png");
  }

  .top {
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 2;
  }
  .top .inner {
    position: relative;
    min-width: 100%;
    height: auto;
    padding: 140px 10px 60px 10px;
    box-sizing: border-box;
    margin: 0 auto;
    background: var(--color-green) url(assets/img/main/bg_main_visual_m.png)
      center top no-repeat;
    background-size: 100% auto;
  }
  .top .inner h1 {
    margin-bottom: 16px;
    margin: 0 0 22px;
    padding: 0 10px;
    text-align: center;
    font-size: 34px;
    line-height: 1.24;
    letter-spacing: -0.34px;
  }
  .top .inner p {
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: -0.16px;
    opacity: 0.85;
  }
  .top .inner .btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: auto;
    padding: 32px 10px 48px 10px;
  }
  .top .inner .btns a {
    float: none;
    margin: 0;
    width: 100%;
    height: 52px;
    font-size: 15px;
    font-weight: bold;
    line-height: 52px;
    padding-left: 12px;
    border-radius: 6px;
    background: var(--color-font-secondary) url("assets/img/main/ico_top.png")
      right 12px center no-repeat;
    background-size: 18px auto;
  }
  .top .inner:after {
    position: relative;
    left: initial;
    bottom: initial;
    margin-left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 53.88vw;
    border-radius: 12px;
    box-shadow: 0 15px 20px 0 rgba(51, 61, 74, 0.12);
    background: url("assets/img/main/img_visual.png") no-repeat;
    background-size: 100% auto;
  }

  .roi {
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
  }
  .roi .inner {
    position: relative;
    min-width: auto;
    padding: 158px 20px 120px 20px;
    box-sizing: border-box;
    box-sizing: border-box;
    background: url("assets/img/main/bg_roi_m.png") center 30px no-repeat;
    background-size: 344px auto;
  }
  .roi h2 {
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.2px;
  }
  .roi h2 span {
    display: block;
  }
  .roi h2 br {
    display: none;
  }
  .roi h2 em {
    display: block;
    font-style: normal;
    color: var(--color-font-green);
  }
  .roi .list {
    grid-template-columns: 1fr;
    gap: 16px;
    width: 100%;
  }
  .roi .item {
    width: 100%;
    height: auto;
    padding: 23px 20px;
    border-radius: 6px;
  }
  .roi .item dt {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 1.6;
  }
  .roi .item dd {
    font-size: 14px;
    line-height: 1.57;
  }
  .roi .bg-dot {
    padding: 40px 0 32px 0;
    text-align: center;
  }
  .roi .bg-dot span {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 0 15px;
    border-radius: 6px;
    background: var(--color-green);
  }
  .roi .txt {
    font-size: 15px;
    line-height: 1.6;
  }

  .solutions {
    clear: both;
    position: relative;
    width: 100%;
    z-index: 1;
    overflow: initial;
    background: var(--color-bg-main);
  }
  .solutions:before {
    position: absolute;
    left: 20px;
    top: -40px;
    content: "";
    display: block;
    width: 80px;
    height: 80px;
    background: url("assets/img/main/bg_dot_m.png") no-repeat;
    background-size: 80px auto;
  }
  .solutions .inner {
    width: auto;
    height: auto;
    padding: 80px 0 120px 0;
  }
  .solutions .inner:before {
    position: relative;
    right: initial;
    top: initial;
    display: block;
    width: 100%;
    height: 61.66vw;
    background: url("assets/img/main/bg_solutions_m.png") center no-repeat;
    background-size: 100% auto;
  }
  .solutions h2 {
    padding: 58px 20px 16px 20px;
    font-size: 26px;
    margin: 0;
    line-height: 1;
    letter-spacing: -0.26px;
    text-transform: uppercase;
  }
  .solutions p {
    width: auto;
    font-size: 15px;
    line-height: 1.6;
    padding: 0 20px;
  }
  .solutions .btns {
    overflow: hidden;
    padding: 24px 20px 0 20px;
  }
  .solutions .btns a {
    float: none;
    margin-right: 0;
    width: 100%;
    height: 52px;
    line-height: 52px;
    font-size: 15px;
    margin-bottom: 12px;
  }

  .technology {
    clear: both;
    position: relative;
    width: 100%;
    z-index: 1;
    overflow: initial;
    background: var(--color-bg-main);
  }
  .technology:after {
    position: absolute;
    right: 20px;
    bottom: -40px;
    content: "";
    display: block;
    width: 80px;
    height: 80px;
    background: url("assets/img/main/bg_dot_m.png") no-repeat;
    background-size: 80px auto;
  }
  .technology .inner {
    width: auto;
    height: auto;
    padding: 0 0 68px 0;
  }
  .technology .inner:before {
    position: relative;
    left: initial;
    top: initial;
    display: block;
    width: 100%;
    height: 56.66vw;
    background: url("assets/img/main/bg_technology_m.png") center no-repeat;
    background-size: 100% auto;
  }
  .technology h2 {
    padding: 58px 20px 16px 20px;
    font-size: 26px;
    line-height: 1;
    letter-spacing: -0.26px;
    text-transform: uppercase;
  }
  .technology p {
    width: auto;
    font-size: 15px;
    line-height: 1.6;
    padding: 0 20px;
  }
  .technology .btns {
    overflow: hidden;
    padding: 24px 20px 0 20px;
  }
  .technology .btns a {
    float: none;
    margin-right: 0;
    width: 100%;
    height: 52px;
    line-height: 52px;
    font-size: 15px;
    margin-bottom: 12px;
  }

  .ai-with-patents {
    text-align: center;
  }
  .ai-with-patents .inner {
    width: 100%;
    margin: 0 auto;
    padding: 120px 20px 80px 20px;
    box-sizing: border-box;
  }
  .ai-with-patents h2 {
    margin-bottom: 16px;
    font-size: 26px;
    line-height: 2.31;
    letter-spacing: -0.26px;
  }
  .ai-with-patents p {
    width: auto;
    margin: 0 auto 32px auto;
    font-size: 15px;
    line-height: 1.6;
  }
  .ai-with-patents .list {
    /* display: grid;
    grid-template-columns: 1fr;
    gap: 16px; */
  }
  .ai-with-patents .item {
    height: auto;
    padding: 32px 20px;
    border-radius: 6px;
  }
  .ai-with-patents .item dt {
    font-size: 15px;
    font-weight: 800;
    line-height: 1.87;
    margin-bottom: 12px;
  }
  .ai-with-patents .item dd {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 1.57;
  }
  .ai-with-patents .item .btn-more {
    position: relative;
    left: initial;
    bottom: initial;
    width: 210px;
    margin: 0 auto;
  }

  .contact-us {
    /* width: auto !important;
    height: auto !important;
    padding: 84px 20px !important;
    margin: 0 20px !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    overflow: hidden !important;
    text-align: center !important;
    background: url("assets/img/main/bg_contact_m.png") no-repeat !important;
    background-size: cover !important; */
  }
  .contact-us h2 {
    margin-bottom: 24px;
    font-size: 24px;
    letter-spacing: -0.24px;
    text-transform: uppercase;
  }
  .contact-us p {
    margin-bottom: 48px;
    opacity: 0.6;
    font-size: 14px;
    line-height: 1.57;
    color: #fff;
  }
  .contact-us .btn-more {
    position: relative;
    right: initial;
    top: initial;
    display: block;
    width: 210px;
    height: 48px;
    margin: 0 auto;
    line-height: 48px;
    opacity: 0.8;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
}
